/*jshint esversion: 11 */
const transformBodlConsentPayload = (categories) => {
    const generateEventId = () => crypto && crypto.randomUUID ? crypto.randomUUID() : '';

    const consentCategoryToBodlMap = {
        functional: 2,
        analytics: 3,
        advertising: 4
    };

    const allowed = Array.isArray(categories) ? categories : [];

    return {
        event_id: generateEventId(),
        functional: allowed.includes(consentCategoryToBodlMap.functional),
        analytics: allowed.includes(consentCategoryToBodlMap.analytics),
        advertising: allowed.includes(consentCategoryToBodlMap.advertising),
    };
};

const triggerConsentUpdatedEvent = (allowedCategories) => {
    if (!window.bodlEvents || !window.bodlEvents.consent || typeof window.bodlEvents.consent.emitConsentUpdatedEvent !== 'function') {
        return ;
    }

    window.bodlEvents.consent.emitConsentUpdatedEvent(transformBodlConsentPayload(allowedCategories));
};
